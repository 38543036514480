<template lang="pug">
Popup(
  title="Idle Warning",
  :ok="!isSessionExpired ? 'Continue' : 'Login'",
  :closeOnOkClick="!isSessionExpired",
  :visible="sessionTime !== ''",
  @hiding="sessionTime = ''",
  @ok="onSessionTimeoutClick"
)
  div(v-if="!isSessionExpired")
    p Your session has been idle for a while. Your session will time out in:
    h6.text-center {{ sessionTime }}
  div(v-else)
    p.text-center Your session has timed out. Please log back in to continue.
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { BasePopup as Popup } from "@cruciallearning/puddle";

@Component({
  components: { Popup },
})
export default class SessionTimeout extends Vue {
  @Prop({ required: false, type: Boolean }) readonly visible!: boolean;

  private isSessionExpired = false;
  private sessionTimer?: NodeJS.Timeout;
  private sessionTime = "";

  mounted(): void {
    this.startSessionTimeout();
  }

  async startSessionTimeout(alertStartTime = 60): Promise<void> {
    // TODO find out if this is needed
    // const token = await getInstance().getToken();
    // this.sessionTimer = setInterval(() => {
    //   if (token) {
    //     const expire = (this.$keycloak.tokenParsed.exp as number) * 1000;
    //     const duration = Math.round((expire - DateTime.now().toMillis()) / 1000);
    //     if (duration > alertStartTime) return;
    //     else if (duration <= alertStartTime && duration > 0) {
    //       const expires = DateTime.now().plus({ milliseconds: expire });
    //       const seconds = expires.diffNow("seconds").seconds;
    //       if (seconds <= 30) {
    //         this.sessionTime = "less than a minute";
    //       } else if (seconds <= 90) {
    //         this.sessionTime = "1 minute";
    //       } else {
    //         this.sessionTime = Math.round(seconds / 60) + " minutes";
    //       }
    //     } else {
    //       this.stopSessionTimeout();
    //       this.isSessionExpired = true;
    //     }
    //   }
    // }, 1000);
  }

  stopSessionTimeout(): void {
    if (this.sessionTimer) clearInterval(this.sessionTimer as NodeJS.Timeout);
  }

  onSessionTimeoutClick(): void {
    // TODO find out if this is needed
    // if (!this.isSessionExpired) {
    //   this.stopSessionTimeout();
    //   this.$keycloak.updateToken(70).then(() => this.startSessionTimeout());
    // } else this.$keycloak.endSession();
  }
}
</script>
