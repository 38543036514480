<template lang="pug">
DxPopup.dx-popup-status(
  closeOnCancelClick=false,
  closeOnOutsideClick=false,
  dragEnabled=false,
  resizeEnabled=false,
  showCloseButton=false,
  showTitle=false,
  :visible="statusVisible",
  width="94%",
  :height="getHeight",
  minWidth="500px",
  @hiding="onHiding",
  shading,
  shading-color="#bfbfbf"
)
  DxPosition(my="top", at="top", offset="0 20px", of="window")
  table
    tr
      td.status-data(width=50, valign="center")
        Icon(value="tool", height="35px", width="35px", color="black", dataName="maintenance-icon")
      td.status-data(valign="center")
        span.status-message(data-name="status-message", v-html="getStatus()")
      td.status-data(width=120, valign="center")
        Button.got-it-button(small, text="Got It", @click="onHiding", dataName="got-it-button")
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";
import { DisplayedStatus, GetActiveStatus } from "@/store/modules/SystemStatus/model";
import { DxPopup, DxPosition, DxToolbarItem } from "devextreme-vue/popup";
import { BaseButton as Button } from "@cruciallearning/puddle";
import Icon from "@/components/Icons/Icon.vue";

@Component({
  components: { Button, DxPopup, DxPosition, DxToolbarItem, Icon },
  computed: { ...mapState(["headerType"]), ...mapState("StatusModule", ["messages"]) },
  methods: {
    ...mapActions("StatusModule", ["getActiveStatus"]),
    ...mapMutations("StatusModule", ["displayedStatus", "clearActiveStatus"]),
  },
})
export default class BaseHeader extends Vue {
  private readonly getActiveStatus!: GetActiveStatus;
  private readonly displayedStatus!: DisplayedStatus;

  private readonly messages!: string[];
  private statusVisible = false;

  async created(): Promise<void> {
    await this.getActiveStatus();
    this.statusVisible = this.messages.length > 0;
  }

  getStatus(): string {
    return this.messages.join("<br><hr>");
  }

  getStatusText() {
    let message = this.messages.join("\n________________________________________\n\n");
    message = message.replaceAll(/<br\/*>/gi, "\n");
    message = message.replaceAll(/<.+?>/gi, "");
    return message;
  }

  getHeight(): string {
    const rows = this.getStatusText().split("\n");
    const height = 40 + this.messages.length * 20 + (rows.length + 2) * 17;
    return height + "px";
  }

  onHiding(): void {
    this.statusVisible = false;
    this.displayedStatus();
  }
}
</script>
