import Config from "../../config.json";

type EnvVars = {
  backendUrl: string;
  cdnUrl: string;
  securityRealm: string;
  frostUrl: string;
  vaporUrl: string;
  eap: string[];
  socketUrl: string;
  statusUrl: string;
  buildVersion: string;
  oktaDomain: string;
  oktaClientId: string;
  osanoUrl: string;
};
const envVars: EnvVars = {
  backendUrl: Config.BACKEND_URL,
  cdnUrl: Config.CDN_URL,
  frostUrl: Config.FROST_URL,
  vaporUrl: Config.VAPOR_URL,
  securityRealm: Config.SECURITY_CLIENT_REALM,
  eap: Config.EAP.split(","),
  socketUrl: Config.SOCKET_URL,
  statusUrl: Config.STATUS_URL,
  oktaDomain: Config.OKTA_DOMAIN,
  oktaClientId: Config.OKTA_CLIENT_ID,
  osanoUrl: Config.OSANO_URL,
  buildVersion: Config.BUILD_VERSION,
};

const showError: (x: string) => void = (varName: string): void => {
  console.error(`Missing ${varName} environment variable`);
};
if (!envVars.backendUrl) showError(`BACKEND_URL`);
if (!envVars.cdnUrl) showError(`CDN_URL`);
if (!envVars.securityRealm) showError(`SECURITY_REALM`);
if (!envVars.frostUrl) showError(`FROST_URL`);
if (!envVars.vaporUrl) showError(`VAPOR_URL`);
if (!envVars.statusUrl) showError(`STATUS_URL`);
if (!envVars.buildVersion) envVars.buildVersion = "unknown";

export default envVars;
