import { Module } from "vuex";
import { VirtualCourseState } from "./model";

const VirtualCourseModule: Module<VirtualCourseState, unknown> = {
  namespaced: true,
  state() {
    return {
      entries: [],
    };
  },
  getters: {
    getMaxSessions(state: VirtualCourseState): (productId: string) => string {
      return (productId: string) => {
        const productIds = state.entries.find((e) => e.productReference.uid === productId);
        return productIds ? productIds.maxNumberOfSessions : "";
      };
    },
    getMaxSessionLength(state: VirtualCourseState): (courseName: string) => string | number {
      return (productId: string) => {
        const findMaxSessionLength = state.entries.find((e) => e.productReference.uid === productId);
        return findMaxSessionLength ? findMaxSessionLength.maxSessionLength : 0;
      };
    },
  },
  mutations: {
    setVirtualCourseState(state: VirtualCourseState, changes: Partial<VirtualCourseState>): void {
      Object.assign(state, changes);
    },
  },
};
export default VirtualCourseModule;
