<template lang="pug">
ul.list-unstyled(@mouseenter="onMouseEnter", @mouseleave="onMouseLeave")
  li(v-for="subitem in menu")
    router-link.submenu-item(
      :to="subitem.route",
      @click.native="onClick",
      :data-name="`submenu-${subitem.name.toLowerCase()}`"
    ) {{ subitem.title }}
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { SubmenuItem } from "@/store/modules/Base/model";

@Component({})
export default class BaseSubmenu extends Vue {
  @Prop({ required: true }) readonly menu!: SubmenuItem[];

  onMouseEnter(): void {
    this.$emit("mouseenter");
  }
  onMouseLeave(): void {
    this.$emit("mouseleave");
  }
  onClick(): void {
    this.$emit("click");
  }
}
</script>
