enum EventDetailsTabs {
  OD_LEARNERS = "0.4",
  OD_EVENT_DETAILS = "0.5",
  MATERIALS = "1",
  SDI_ASSESSMENT = "1.5",
  SESSIONS = "2",
  REGISTRATION = "3",
  NOTES = "4",
  ABOUT = "5",
}

export default EventDetailsTabs;
