import router, { createRouting } from "@/router";
import { IconModel } from "@/store/model";
import { Module } from "vuex";
import { BaseCreateEventModel, RegistrationDetailsModel, BaseState, NavigationItem } from "./model";

/**
 * --< Base Module >--
 * The Base Module contains store data for base/global
 * layout components such as headers, sidebars and
 * footers.
 */
const BaseModule: Module<BaseState, unknown> = {
  namespaced: true,
  state(): BaseState {
    return {
      createEvent: [],
      filters: [],
      footer: undefined,
      learningFormatTypes: [],
      navigation: [],
      sidebar: undefined,
      registrationDetails: [],
      errorTitle: "Error occurred",
      errorHeight: undefined,
      error: "",
      popupTitle: "",
      popupText: "",
    };
  },
  getters: {
    getCreateEventPage(state: BaseState): (page: number) => BaseCreateEventModel[] {
      return (page: number): BaseCreateEventModel[] =>
        state.createEvent.filter((key) => key.page === page && key.type.includes("all"));
    },
    getEventsDetailTab(state: BaseState): (tab: string, type: string) => RegistrationDetailsModel[] {
      return (tab: string, type = "All"): RegistrationDetailsModel[] =>
        state.registrationDetails.filter((key) => key.tab === tab && key.type === type);
    },
    getMenuOnlyNavigationItems(state: BaseState): NavigationItem[] {
      return state.navigation.filter((key) => key.menuItem);
    },
    getLearningFormatType(state: BaseState): (type: string) => IconModel | undefined {
      return (learningFormatType: string): IconModel | undefined =>
        state.learningFormatTypes.find((item) => item.id === learningFormatType) || undefined;
    },
    getNavigationItem(state: BaseState): NavigationItem | undefined {
      return state.navigation.find((navItem) => navItem.id === router.currentRoute.meta?.id);
    },
    getUnauthorizedPaths(state: BaseState): string[] {
      return state.navigation.filter((navItem) => !navItem.auth?.isAuthenticated).map((e) => e.route);
    },
  },
  mutations: {
    setBaseStateLocal(state: BaseState, baseStateOptions): void {
      Object.keys(baseStateOptions)
        .filter((key) => key in state)
        .forEach((key) => {
          (state as any)[key] = baseStateOptions[key];
          if (key.toLowerCase() === "navigation") createRouting(state.navigation);
        });
    },
    setBaseState(state: BaseState, changes: Partial<BaseState>): void {
      Object.assign(state, changes);
    },
    setError(state: BaseState, error: string): void {
      state.error = error;
    },
    resetError(state: BaseState): void {
      state.error = "";
      state.errorTitle = "Error occurred";
      state.errorHeight = undefined;
    },
    setPopupText(state: BaseState, data: { text: string; title: string }): void {
      state.popupText = data.text;
      state.popupTitle = data.title;
    },
    resetPopupText(state: BaseState): void {
      state.popupText = ``;
      state.popupTitle = ``;
    },
  },
};

export default BaseModule;
