import { http } from "@/services/http";
import routes from "@/services/routes";
import { ReportContentModel } from "@/store/modules/Report/model";

const Report = {
  getClientReport(clientId: string, courseId: string): Promise<ReportContentModel | null> {
    const endpoint = `?courseId=${courseId}`;
    return http
      .get<ReportContentModel>(`${routes(clientId).CLIENT_REPORT}${endpoint}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) console.clear();
        return null;
      });
  },
  getClientReportExcel(clientId: string, courseId: string): Promise<Blob | null> {
    const endpoint = `?courseId=${courseId}`;
    return http
      .get<Blob>(`${routes(clientId).CLIENT_REPORT_EXCEL}${endpoint}`, { responseType: "blob" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) console.clear();
        return null;
      });
  },
  getClientReportURL(clientId: string, year: string, courseId: string): string {
    const endpoint = `?year=${year}&course=${courseId}`;
    return `${routes(clientId).CLIENT_REPORT_EXCEL}${endpoint}`;
  },
};
export default Report;
