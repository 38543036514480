import { NavigationGuardNext, Route } from "vue-router";
import { AuthInstance, getInstance } from "@cruciallearning/puddle/auth";
import Util from "./Util";
import { BaseAuthModel } from "@/store/modules/Base/model";
import router from "@/router";

export const authGuard = (to: Route, from: Route, next: NavigationGuardNext) => {
  const instance: AuthInstance = getInstance();
  const fn = async () => {
    const meta = to.meta as BaseAuthModel;
    const user = instance.authUser;

    const logBase = `Navigating ${user.given_name} ${user.family_name} to `;
    if (!meta.isAuthenticated) {
      console.log(`Navigating to UNAUTHENTICATED page ${to.fullPath} from ${from.fullPath}`);
      return next();
    }
    if (instance.isAuthenticated) {
      if (Util.isAuthorized(meta)) {
        console.log(`${logBase} AUTHENTICATED page ${to.fullPath}`);
        return next();
      } else {
        router.push("/unauthorized").catch(() => undefined);
        return false;
      }
    }
    console.log(`${logBase} ${to.path} AFTER login redirect`);
    instance.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  if (!instance.authLoading) {
    return fn();
  }

  instance.$watch("authLoading", (loading: boolean) => {
    if (loading === false) {
      return fn();
    }
  });
};
