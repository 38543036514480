import { Module } from "vuex";
import { StepState } from "./model";

const StepModule: Module<StepState, unknown> = {
  namespaced: true,
  state(): StepState {
    return {
      activeStep: 0,
      completedSteps: [],
      isComplete: false,
      isEditing: false,
      isStepValid: true,
      totalSteps: 0,
      validChecks: new Map<number, () => boolean>(),
      currentComponent: "",
      steps: [],
      singleStepOverride: "",
      confirmCancel: false,
      cancelConfirmed: false,
      showConfirmPopup: false,
      disableNext: false,
    };
  },
  mutations: {
    setStepState(state: StepState, stepStateOptions: Partial<StepState>): void {
      Object.assign(state, stepStateOptions);
    },
    resetSteps(state: StepState): void {
      state.activeStep = 0;
      state.completedSteps.length = 0;
      state.isComplete = false;
      state.isStepValid = true;
      state.totalSteps = 0;
      state.validChecks.clear();
      state.steps = [];
    },
    resetCompletedSteps(state: StepState): void {
      state.completedSteps.length = 0;
    },
    setActiveStep(state: StepState, activeStep: number): void {
      state.activeStep = activeStep;
    },
    resetSingleStep(state: StepState): void {
      state.singleStepOverride = "";
    },
    setCompletedStep(state: StepState, completedStep: number): void {
      if (state.completedSteps.includes(completedStep)) return;
      state.completedSteps.push(completedStep);
      state.isComplete = state.completedSteps.length === state.totalSteps;
    },
    setTotalSteps(state: StepState, totalSteps: number): void {
      state.totalSteps = totalSteps;
    },
    setIsStepValid(state: StepState, isValid: boolean): void {
      state.isStepValid = isValid;
    },
    setCurrentComponent(state: StepState, currentComponent: string): void {
      state.currentComponent = currentComponent;
    },
    setSteps(state: StepState, steps: string[]) {
      state.steps = steps;
    },
  },
};

export default StepModule;
