<template lang="pug">
.ti-loader
  .ti-spinner
    .ti-spinner-bounce1
    .ti-spinner-bounce2
    .ti-spinner-bounce3
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
@Component
export default class TILoader extends Vue {}
</script>
<style lang="scss" scoped>
.ti-loader {
  background: rgba(255, 255, 255, 0.7);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  display: flex;
}
.ti-spinner {
  margin: auto;
  text-align: center;
}
.ti-spinner > div {
  -webkit-animation: bouncedelay 1.4s ease-in-out infinite;
  animation: bouncedelay 1.4s ease-in-out infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  background-color: #656565;
  border-radius: 100%;
  display: inline-block;
  height: 2.5em;
  width: 2.5em;
}
.ti-spinner-bounce1 {
  -webkit-animation-delay: -0.32s !important;
  animation-delay: -0.32s !important;
}
.ti-spinner-bounce2 {
  -webkit-animation-delay: -0.16s !important;
  animation-delay: -0.16s !important;
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
