import store from "@/store";
import { BaseAuthModel } from "@/store/modules/Base/model";
import config from "@/config";
import { getInstance } from "@cruciallearning/puddle/auth";

const Util = {
  isAuthorized(auth: BaseAuthModel): boolean {
    let hasRole = false;
    const authInstance = getInstance();
    const roles = authInstance.authUser.roles;
    if (Array.isArray(auth.inRealmRole)) {
      auth.inRealmRole.forEach((role) => {
        hasRole = hasRole || roles?.findIndex((e) => e === role) != -1;
      });
    } else {
      if (auth?.inRealmRole) {
        const singleton = (auth.inRealmRole as string) || "";
        hasRole = roles?.findIndex((e) => e === singleton) != -1;
      }
    }

    let skipRole = false;
    if (auth.exceptRole) {
      if (Array.isArray(auth.exceptRole)) {
        auth.exceptRole.forEach((role) => {
          skipRole = skipRole || roles?.findIndex((e) => e === role) != -1;
        });
      } else {
        if (auth.exceptRole) {
          const singleton = (auth.exceptRole as string) || "";
          skipRole = roles?.findIndex((e) => e === singleton) != -1;
        }
      }
    }
    if (authInstance.LEARNING_MANAGER) {
      const eap = config.eap.length > 0;
      if (auth.eapDependentLM && eap) {
        const eapLM = store.getters["SecurityModule/isEapLM"];
        if (eapLM == undefined || eapLM == false) return false;
      }
    }
    return hasRole && !skipRole;
  },
};
export default Util;
