import { EventContentModel, EventModel } from "@cruciallearning/puddle/models/event";
import constants from "@/utils/constants";
export const event: EventContentModel = {
  audit: {},
  salesForceId: "",
  clientName: "",
  courseId: "",
  clientId: "",
  courseName: "",
  courseContentStackId: "",
  contentStackProductId: "",
  details: "",
  eventStatus: "PUBLISHED",
  id: "",
  learningFormatType: constants.eventType.materialsOnly,
  learnerMaterials: [],
  includesPhysicalBook: false,
  preWorkMaterialIds: [],
  registrationType: "REGISTRATION",
  redemptionCodes: [],
  redeemedCount: 0,
  seatCount: 0,
  title: "",
  learningSessions: [],
  startDate: new Date(),
  expirationDate: new Date(),
  originalMaxCount: null,
  onDemandCourseId: "",
  onDemandEventId: "",
  sessionTimeZone: {
    id: "",
    offset: 0,
    title: "",
  },
  registrationCode: {
    code: "",
    redeemedCount: 0,
    availableCount: 0,
    registrants: [],
    startDate: new Date(),
    endDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
  },
  materialDownloads: [],
  clientCourseTokens: [],
  courseNotes: [],
  originalSeatCount: 0,
  referenceNumber: "",
  eap: [],
  expired: false,
  skipAutoReclaim: false,
  practice: false,
  systemCheck: false,
  pendingCode: false,
};
export const events: EventModel = { content: [] };
