import { BaseState } from "@/store/modules/Base/model";
import { http } from "@/services/http/";
import routes from "../routes";
import store from "@/store";
import client from "./requests/client";
import report from "./requests/report";
import { registrationCode } from "./requests/registrationCode";
import course from "./requests/course";
import download from "./requests/download";
import event from "./requests/event";
import filter from "./requests/filter";
import security from "./requests/security";
import learner from "./requests/learner";
import license from "./requests/liscense";
import moderator from "./requests/moderator";
import platformUser from "./requests/platformUser";
import { onDemand } from "./requests/onDemand";
import cdn from "./requests/cdn";
import auditron from "@cruciallearning/puddle/api/auditron";
import documentation from "./requests/documentation";
import eventConfig from "./requests/eventConfig";

class Api {
  public auditron = auditron;
  public cdn = cdn;
  public client = client;
  public course = course;
  public documentation = documentation;
  public download = download;
  public event = event;
  public filter = filter;
  public learner = learner;
  public license = license;
  public moderator = moderator;
  public onDemand = onDemand;
  public platformUser = platformUser;
  public registrationCode = registrationCode;
  public report = report;
  public security = security;
  public eventConfig = eventConfig;

  get routes() {
    return routes();
  }

  async getBase(language?: string): Promise<void> {
    if (language) store.commit("setRootState", { language });
    await http.get<BaseState>(this.routes.BASE).then((response) => {
      store.commit("BaseModule/setBaseStateLocal", response.data);
    });
  }
}
export default new Api();
