import { Module } from "vuex";
import { LocalizationState } from "./model";

const LocalizationModule: Module<LocalizationState, unknown> = {
  namespaced: true,
  state() {
    return {
      entries: [],
    };
  },
  getters: {
    getValue(state: LocalizationState): (key: string) => string {
      return (key: string) => {
        const findKey = state.entries.find((e) => e.key === key);
        return findKey ? findKey.value : "";
      };
    },
  },
  mutations: {
    setLocalizationState(state: LocalizationState, changes: Partial<LocalizationState>): void {
      Object.assign(state, changes);
    },
  },
};
export default LocalizationModule;
