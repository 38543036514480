import { http } from "@/services/http";
import routes from "@/services/routes";

import { OnDemandEvent, OnDemandLearnerDataModel, OnDemandLearnerStats } from "@/store/modules/OnDemand/model";

import store from "@/store";
import { EventContentModel } from "@cruciallearning/puddle/models/event";
import Utils from "@/utils";

export const onDemand = {
  eventProgress(userId?: string, courseId?: string): Promise<OnDemandEvent> {
    return http
      .post<OnDemandEvent>(`${routes().ON_DEMAND}/event/progress`, { userId, courseId })
      .then((response) => response.data);
  },
  licenseUser(event: EventContentModel): Promise<OnDemandEvent> {
    return http
      .post<OnDemandEvent>(routes(event.onDemandEventId).ON_DEMAND_LICENSE_USER, {})
      .then((response) => response.data);
  },
  panoramaCleanupReport(): Promise<Blob | void> {
    return http
      .get<Blob>(`${routes().ON_DEMAND}/report/panorama`, { responseType: "blob", timeout: 1800000 })
      .then((response) => response.data as Blob)
      .catch((err) => {
        console.error(`DOWNLOAD ERROR:`, err);
        store.commit(`BaseModule/setError`, `Download failed. Please try again or contact help@cruciallearning.com.`);
      });
  },
  certificateUrl(email: string | undefined, courseId: string | undefined): Promise<string> {
    return http
      .post(`${routes().ON_DEMAND}/event/certificate`, { email, courseId })
      .then((response) => response.data.certificateUrl || "");
  },
  eventLearnersStats(eventId: string | undefined, courseId: string | undefined): Promise<OnDemandLearnerStats> {
    return http
      .post(`${routes().TRAINING_EVENTS}/learners/statistics`, { onDemandEventId: eventId, courseId })
      .then((response) => response.data);
  },
  eventLearnersReport(eventId: string | undefined, courseId: string | undefined): Promise<OnDemandLearnerDataModel[]> {
    return http
      .post(`${routes().TRAINING_EVENTS}/learners/report?size=0`, { onDemandEventId: eventId, courseId })
      .then((response) => response.data.content);
  },
  learnersPDFReport(eventId: string, courseId: string | undefined): Promise<OnDemandLearnerDataModel[]> {
    return http
      .post(`${routes().TRAINING_EVENTS}/learners/report?size=0`, { onDemandEventId: eventId, courseId })
      .then((response) => response.data.content);
  },
  learnersExcelReport(eventId: string | undefined, courseId: string | undefined): Promise<Blob | null> {
    return http
      .post<Blob>(
        `${routes().TRAINING_EVENTS}/learners/report/excel`,
        {
          onDemandEventId: eventId,
          courseId,
        },
        { responseType: "blob" }
      )
      .then((response) => response.data)
      .catch((err) => {
        console.log(`DOWNLOAD ERROR:`, err);
        store.commit(`BaseModule/setError`, `Download failed. Please try again or contact help@cruciallearning.com.`);
        return null;
      });
  },
  getTiUrl(
    clientId: string | undefined,
    contractGroupId: string | undefined,
    courseSlug: string | undefined,
    isModerator: boolean | false,
    tiRoute?: string | ""
  ): Promise<string> {
    const gtag = Utils.getCookieValue("VitalSmarts_CookiePolicy") ? "GTM-PLM8L5" : "GTM-PHZS2K4";
    tiRoute = tiRoute || "";
    return http
      .post(`${routes().ON_DEMAND}/link`, {
        clientId,
        contractGroupId,
        courseSlug,
        isModerator,
        tiRoute,
        gtag,
        clpReturn: window.location.href,
      })
      .then((response) => response.data);
  },
};
