import { CourseContentModel, CourseModel } from "@/store/modules/Course/model";
import { http } from "@/services/http";
import routes from "@/services/routes";
import responses from "@/services/responses";
import config from "@/config";

const Course = {
  getCourses(eap?: string[], includeSunset = false): Promise<CourseModel | null> {
    let parameter = eap == undefined ? `?eap=${config.eap}&size=1000` : `?eap=${eap}&size=1000`;
    if (includeSunset) parameter += "&includeSunset=true";
    return http
      .get<CourseModel>(routes(parameter).COURSES)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response && error.response.status === 404) console.clear();
        return null;
      });
  },
  getCourse(courseId: string): Promise<CourseContentModel | null> {
    return http
      .get<CourseContentModel>(routes(`${courseId}?eap=${config.eap}`).COURSES)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response && error.response.status === 404) console.clear();
        return null;
      });
  },
  createCourse(course: CourseContentModel): Promise<string> {
    return http
      .post(routes().COURSES, course)
      .then(() => responses.SUCCESS)
      .catch((err) => {
        console.log(err);
        return responses.FAILURE;
      });
  },
  updateCourse(course: CourseContentModel): Promise<string> {
    return http
      .put(routes(course.id).COURSES, course)
      .then(() => responses.SUCCESS)
      .catch((err) => {
        console.log(err);
        return responses.FAILURE;
      });
  },
};
export default Course;
