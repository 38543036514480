import config from "@/config";
import { http } from "@/services/http";
import routes from "@/services/routes";
import store from "@/store";
import { CourseMetaData } from "@/store/modules/Course/model";
import { GlobalIconModel } from "@/store/modules/Icon/model";
import { LocalizedKeyValuePair } from "@/store/modules/Localization/model";
import { ProductId, VirtualCourseData } from "@/store/modules/VirtualCourse/model";

const cdn = {
  async flush(): Promise<void> {
    return await http.post(`${routes().CDN}/admin/flush`, { updateType: "ALL" });
  },
  async warm(): Promise<void> {
    return await http.post(`${routes().CDN}/admin/warm`, { updateType: "ALL" });
  },
  async pull(): Promise<CdnResponse> {
    return await http.get<CdnResponse>(`${routes().CDN}/platform`).then((res) => res.data);
  },
  async load(): Promise<void> {
    await this.pull().then((res) => this.processResults(res));
  },
  processResults(res: CdnResponse): void {
    if (res) {
      if (res.courses) {
        const courses: CourseMetaData[] = res.courses.map((entry) => ({
          uid: entry.uid,
          title: entry.title,
          abbreviation: entry.abbreviation,
          shorthandName: entry.shorthandName,
          aboutDescription: entry.aboutDescription,
          aboutHeader: entry.aboutHeader,
          materialDescription: entry.materialDescription,
          materialHeader: entry.materialHeader,
          materialImageUrl: entry.materialImageUrl,
          materialDownloadTitle: entry.materialDownloadTitle,
          materialDownloadDescription: entry.materialDownloadDescription,
          ebookDownloadImageUrl: entry.ebookDownloadImageUrl,
          ebookDownloadTitle: entry.ebookDownloadTitle,
          ebookDownloadDescription: entry.ebookDownloadDescription,
          physicalBookImageUrl: entry.physicalBookImageUrl,
          physicalBookDescription: entry.physicalBookDescription,
          physicalBookTitle: entry.physicalBookTitle,
          ebookFormSiteId: entry.ebookFormSiteId,
          ebookFormSiteName: entry.ebookFormSiteName,
          ebookFormSiteUrl: entry.ebookFormSiteUrl,
          ebookEmbedKey: entry.ebookEmbedKey,
          ebookEmbedPrePop: entry.ebookEmbedPrePop,
          ebookEmbedWidth: entry.ebookEmbedWidth,
          preworkImageUrl: entry.preworkImageUrl,
          preworkDownloadTitle: entry.preworkDownloadTitle,
          preworkDownloadDescription: entry.preworkDownloadDescription,
          tiCourseContentId: this.getTiId(entry),
          tiCourseContentSlug: this.getTiSlug(entry),
          familyName: entry.familyName,
          familyId: entry.familyId,
          familyClassification: entry.familyClassification,
          familyBackgroundImg: entry.familyBackgroundImg,
          familyIcon: entry.familyIcon,
          familyLogo: entry.familyLogo,
        }));
        if (courses) {
          store.commit("CourseModule/setCourseState", { metaData: courses });
        }
        if (res.virtualCourses) {
          const virtualCourses: VirtualCourseData[] = res.virtualCourses.map((entry) => ({
            uid: entry.uid,
            productReference: entry.productReference,
            maxSessionLength: entry.maxSessionLength,
            maxNumberOfSessions: entry.maxNumberOfSessions,
            title: entry.title,
          }));
          store.commit("VirtualCourseModule/setVirtualCourseState", { entries: virtualCourses });
        }
      }
    }
  },
  isPlatform(): boolean {
    // KRODD - This is currently the only reason keycloak realm has not been removed 🫠
    return config.securityRealm.toLowerCase() === "platform";
  },
  getTiSlug(entry: CourseEntry): string {
    return this.isPlatform() ? entry.tiCourseContentSlug : entry.tiCourseNonProdSlug;
  },
  getTiId(entry: CourseEntry): string {
    return this.isPlatform() ? entry.tiCourseContentId : entry.tiCourseNonProdId;
  },
  async pullLocalizationUnauth(): Promise<LocalizationResponse> {
    return await http.get<LocalizationResponse>(`${routes().CDN}/localization`).then((res) => res.data);
  },
  async pullIconUnauth(): Promise<IconResponse> {
    return await http.get<IconResponse>(`${routes().CDN}/icon`).then((res) => res.data);
  },
  async loadUnauth(): Promise<void> {
    await this.pullLocalizationUnauth()
      .then((res) => this.processLocalization(res))
      .catch((err) => console.error("error occurred loading localization", err));
    await this.pullIconUnauth()
      .then((res) => this.processIcons(res))
      .catch((err) => console.error("error occurred loading icons", err));
  },
  processLocalization(res: LocalizationResponse): void {
    if (res.mappingEntries) {
      const mappingEntries: LocalizedKeyValuePair[] = res.mappingEntries.map((mapping) => ({
        key: mapping.key,
        value: mapping.value,
      }));
      if (mappingEntries) {
        store.commit("LocalizationModule/setLocalizationState", { entries: mappingEntries });
      }
    }
  },
  processIcons(res: IconResponse): void {
    if (res.entries) {
      const icons: GlobalIconModel[] = res.entries.map((icon) => ({
        title: icon.title,
        url: icon.url,
      }));
      if (icons) {
        store.commit("IconModule/addGlobalIcons", icons);
      }
    }
  },
};
export interface CdnResponse {
  icons: Array<IconEntry>;
  courses: Array<CourseEntry>;
  virtualCourses: Array<VirtualCourseEntry>;
}
export interface LocalizationResponse {
  mappingEntries: Array<LocalizationMappingEntry>;
}
export interface IconResponse {
  entries: Array<IconEntry>;
}
export interface IconEntry {
  title: string;
  url: string;
}
export interface CourseEntry {
  uid: string;
  title: string;
  abbreviation: string;
  shorthandName: string;
  aboutDescription: string;
  aboutHeader: string;
  materialDescription: string;
  materialHeader: string;
  materialDownloadTitle: string;
  materialDownloadDescription: string;
  materialImageUrl: string;
  ebookDownloadImageUrl: string;
  ebookDownloadTitle: string;
  ebookDownloadDescription: string;
  ebookFormSiteName: string;
  ebookFormSiteId: string;
  ebookFormSiteUrl: string;
  ebookEmbedPrePop: string;
  ebookEmbedKey: string;
  ebookEmbedWidth: string;
  physicalBookImageUrl: string;
  physicalBookTitle: string;
  physicalBookDescription: string;
  preworkDownloadTitle: string;
  preworkDownloadDescription: string;
  preworkImageUrl: string;
  tiCourseContentId: string;
  tiCourseContentSlug: string;
  tiCourseNonProdId: string;
  tiCourseNonProdSlug: string;
  familyName: string;
  familyId: string;
  familyClassification: string;
  familyBackgroundImg: string;
  familyIcon: string;
  familyLogo: string;
}
export interface LocalizationMappingEntry {
  key: string;
  value: string;
}

export interface VirtualCourseEntry {
  uid: string;
  productReference: ProductId;
  maxNumberOfSessions: string;
  maxSessionLength: string;
  title: string;
}
export default cdn;
