import { Module } from "vuex";
import { MocktaState } from "./models";

const MocktaModule: Module<MocktaState, unknown> = {
  namespaced: true,
  state() {
    return {
      message: "",
    };
  },
  mutations: {
    setMessage(state: MocktaState, message: string): void {
      state.message = message;
    },
  },
};
export default MocktaModule;
