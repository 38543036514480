import { Module } from "vuex";
import { StatusModel, StatusState } from "@/store/modules/SystemStatus/model";
import StatusHelper from "@/store/modules/SystemStatus/StatusHelper";
import config from "@/config";
import { DateTime } from "luxon";

const StatusModule: Module<StatusState, unknown> = {
  namespaced: true,
  state(): StatusState {
    return {
      messages: [],
      displayed: false,
    };
  },
  actions: {
    async getActiveStatus({ state }): Promise<void> {
      const messages: string[] = [];
      if (!state.displayed) {
        let response;
        try {
          response = await fetch(config.statusUrl + "/status.json?nocache=" + DateTime.now().toMillis());

          if (response.ok) {
            const data = await response.json();
            const conditions = data as StatusModel[];
            if (conditions) {
              conditions.forEach((condition: StatusModel) => {
                if (StatusHelper.isEnabled(condition)) {
                  messages.push(StatusHelper.getMessage(conditions, condition));
                }
              });
            }
          }
        } catch (e) {
          console.log("Error fetching clp status: " + e);
        }
      }
      console.log("Active Status: " + messages.join("\n   "));
      Object.assign(state, { messages: messages });
    },
  },
  mutations: {
    displayedStatus(state: StatusState): void {
      console.log("status was displayed");
      Object.assign(state, { messages: [], displayed: true });
    },
  },
};

export default StatusModule;
