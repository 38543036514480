/*
 * ------------------------------------------------------------------------------
 * Models
 * ------------------------------------------------------------------------------
 */

export interface StatusState {
  messages: string[];
  displayed: boolean;
}

export interface StatusModel {
  id: string;
  display: ConditionTypes;
  start: string;
  end: string;
  message: string;
}

export interface GetActiveStatus {
  (): StatusState;
}

export interface DisplayedStatus {
  (): void;
}

/*
 * ------------------------------------------------------------------------------
 * Types
 * ------------------------------------------------------------------------------
 */
export enum ConditionTypes {
  ON = "on",
  OFF = "off",
  IMMEDIATE = "immediate",
  DEFAULT = "default",
}
