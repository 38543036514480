import { FilterModel } from "@/store/modules/Filter/model";
import { http } from "@/services/http";
import routes from "@/services/routes";
import store from "@/store";

const Filter = {
  async getFilters(type = -1, commit = false): Promise<FilterModel[]> {
    const response = await http.get<FilterModel[]>(routes().FILTERS);
    const filters = type >= 0 ? response.data.filter((item) => item.type === type) : response.data;
    if (commit) store.commit("FilterModule/setFilterState", { filters });
    return filters;
  },
};
export default Filter;
