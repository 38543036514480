const responses = {
  SUCCESS: "success",
  FAILURE: "failed",
  EXISTS: "exists",
  NOT_FOUND: "not found",
  CONFLICT: "conflict",
  UNAVAILABLE: "unavailable",
  FORBIDDEN: "forbidden",
  FREQUENCY: "frequency",
  REUSE: "reuse",
  INVALID_PASSWORD: "failed",
};
export default responses;
