import { FooterTypes, HeaderTypes, RootState } from "@/store/model";
import { NavigationItem } from "@/store/modules/Base/model";
import store from "@/store";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { authGuard } from "@/auth/authGuard";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/landing",
    meta: {
      isAuthenticated: true,
      inRealmRole: "Learner",
      title: "Landing",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export const createRouting = (navigation: NavigationItem[]) => {
  navigation
    .filter(
      (key) => router.getRoutes().find((item) => item.name === `${key.name}-${store.state.language}`) === undefined
    )
    .map((item) => {
      router.addRoute({
        path: item.route,
        beforeEnter: authGuard,
        component: () => import(`@/views/${item.name.split(" ").join("")}.vue`),
        meta: {
          id: item.id,
          isAuthenticated: item.auth?.isAuthenticated,
          inRealmRole: item.auth?.inRealmRole,
          exceptRole: item.auth?.exceptRole,
          eapDependentLM: item.auth?.eapDependentLM,
          title: item.title,
        },
      });
    });
};

router.beforeResolve((to, from, next) => {
  const navigation = store.state.BaseModule?.navigation.find((item) => item.id === to.meta?.id);
  const options: Partial<RootState> = {
    backgroundColorClass: "",
    headerType: HeaderTypes.DARK,
    isSidebarVisible: false,
    isFooterVisible: false,
    lastRoute: from.path,
  };

  if (navigation) options.navItem = navigation;
  if (navigation?.footer) {
    options.isFooterVisible = true;
    options.footerType = FooterTypes[navigation.footer.type];
  }
  if (navigation?.header) {
    let title = navigation.title;
    if (store.state.StepModule?.isEditing && navigation.editing) title = navigation.editing;
    options.headerTitle = title;
    options.headerType = HeaderTypes[navigation.header];
  }

  document.title = `Crucial Learning Platform: ${to.meta?.title}`;
  store.commit("setRootState", options);
  store.dispatch("setReady");

  //<!-- Google Analytics -->
  if (typeof ga !== "undefined") {
    ga("set", "page", to.path);
    ga("send", "pageview");
  }
  //<----------------------->

  next();
});

export default router;
