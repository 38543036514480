import { ConditionTypes, StatusModel } from "@/store/modules/SystemStatus/model";

const StatusHelper = {
  isEnabled(condition: StatusModel) {
    let now;
    let start;
    let end;

    switch (condition.display) {
      case ConditionTypes.IMMEDIATE:
        return true;
      case ConditionTypes.OFF:
      case ConditionTypes.DEFAULT:
        return false;
      case ConditionTypes.ON:
        now = Number(new Date());
        start = StatusHelper.parseDate(condition.start);
        end = StatusHelper.parseDate(condition.end);
        if (start !== null) {
          if (end !== null) {
            return start <= now && now <= end;
          }
          return start <= now;
        }
        return end !== null && now <= end;
      default:
        console.log("ERROR: Unrecognized status condition display type: " + condition.display);
        break;
    }
  },
  getConditionById(conditions: StatusModel[], id: string): StatusModel {
    for (const condition of conditions) {
      if (condition.id === id) {
        return condition;
      }
    }
    return { id: "", display: ConditionTypes.OFF, start: "", end: "", message: "" };
  },
  getMessage(conditions: StatusModel[], condition: StatusModel) {
    let result = condition.message
      .replaceAll("${start}", StatusHelper.formatDate(condition.start))
      .replaceAll("${end}", StatusHelper.formatDate(condition.end));

    let rangeStart = result.indexOf("${range ");
    while (rangeStart >= 0) {
      const rangeEnd = result.indexOf("}", rangeStart);
      const id = result.substring(rangeStart + 8, rangeEnd);
      result = result.replace(
        "${range " + id + "}",
        StatusHelper.getRange(StatusHelper.getConditionById(conditions, id))
      );
      rangeStart = result.indexOf("${range ");
    }
    return result;
  },
  getRange(condition: StatusModel) {
    const start = StatusHelper.parseDate(condition.start);
    const end = StatusHelper.parseDate(condition.end);

    if (start != null) {
      if (end != null) {
        const startFormat = StatusHelper.formatDate(start);
        let endFormat = StatusHelper.formatDate(end);
        let parts = endFormat.split(",");
        if (startFormat.startsWith(parts[0].trim())) {
          parts = parts[parts.length - 1].split("at");
          endFormat = parts[parts.length - 1].trim();
        }
        return startFormat + " - " + endFormat;
      }
      return "beginning on " + StatusHelper.formatDate(start);
    }
    if (end != null) {
      return "ending on " + StatusHelper.formatDate(end);
    }
    return "unspecified";
  },
  parseDate(date: string): number | null {
    const dateObj = new Date(date);
    if (!dateObj) {
      return null;
    }
    return Number(dateObj);
  },
  formatDate(date: string | number): string {
    const dateObj = new Date(date);
    if (!dateObj) {
      return "unspecified";
    }

    return dateObj.toLocaleString("en-US", {
      weekday: "long", // long, short, narrow
      day: "numeric", // numeric, 2-digit
      month: "long", // numeric, 2-digit, long, short, narrow
      hour: "numeric", // numeric, 2-digit
      minute: "numeric", // numeric, 2-digit
    });
  },
};

export default StatusHelper;
