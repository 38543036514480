import config from "@/config";
import { http } from "@/services/http";
import routes from "@/services/routes";

const Documentation = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getSpec(apiName: string): Promise<{ [propName: string]: any }> {
    let route = `${routes(apiName).DOCUMENTATION}`;
    if (apiName === "cache") {
      route = route.replace(`${config.backendUrl}/cache`, config.cdnUrl);
    }
    return await http.get<[string]>(route).then((res) => res.data);
  },
};

export default Documentation;
