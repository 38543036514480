import { ClientContentModel, ClientModel } from "@/store/modules/Client/model";
import api from "@/services/api";
import { getInstance } from "@cruciallearning/puddle/auth";
const RoleScopeAuth = {
  isLearningManagerForClient(salesForceId: string): boolean {
    const auth = getInstance();
    if (auth.LEARNING_MANAGER) {
      return this.hasScopeForRole("Learning Manager", salesForceId);
    } else {
      return false;
    }
  },
  hasScopeForRole(role: string, scope: string): boolean {
    const auth = getInstance();
    const roleScopes = auth.authUser.scopes;
    if (roleScopes) {
      const foundRole = roleScopes.find((current) => current.role === role);
      if (foundRole && foundRole.scopes) {
        const foundScope = foundRole.scopes.find((current: string) => current === scope);
        if (foundScope) {
          return true;
        }
      }
    }
    return false;
  },
  getScopes(role: string): string[] | undefined {
    const auth = getInstance();
    const roleScopes = auth.authUser.scopes;
    if (roleScopes) {
      return roleScopes.find((current) => current.role === role)?.scopes;
    }
    return [];
  },
  getLearningManagerScopes(): string[] | undefined {
    return this.getScopes("Learning Manager");
  },
  async getLearningManagerClients(eap?: string[]): Promise<ClientContentModel[]> {
    const eapString = eap == undefined ? undefined : eap;
    const clients: ClientContentModel[] = [];
    const scopes = this.getLearningManagerScopes();
    if (scopes) {
      for (const scope of scopes) {
        const client = await api.client.getClientById(scope, "externalId", eapString);
        if (client) {
          const clientModel = client as ClientModel;
          if (clientModel === undefined) {
            clients.push(client as ClientContentModel);
          } else {
            clients.push(clientModel.content[0]);
          }
        }
      }
    }
    return clients.sort((a, b) => (a.clientName > b.clientName ? 1 : -1));
  },
};
export default RoleScopeAuth;
export interface RoleScope {
  role: string;
  scopes: string[];
}
