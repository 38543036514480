import { Module } from "vuex";
import { UserModel, UserState } from "./model";

const UserModule: Module<UserState, unknown> = {
  namespaced: true,
  state(): UserState {
    return {
      user: {},
    };
  },
  mutations: {
    setUser(state: UserState, userChanges: UserModel): void {
      Object.assign(state.user, userChanges);
    },
  },
};

export default UserModule;
