<template lang="pug">
header.site-masthead.container.header-white
  .site-logo
    router-link(to="/events", :class="{ 'pointer-disabled': !$auth.isAuthenticated }")
      img(v-if="headerTitle", src="@/assets/images/logos/cl-mark.svg", alt="Crucial Learning Logo")
      img(v-else, src="@/assets/images/logos/cl-logo.svg", alt="Crucial Learning Logo")
  h4 {{ headerTitle }}

  UserMenu(v-if="$auth.isAuthenticated", light)
  SystemStatus
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import UserMenu from "@/components/Header/User/UserMenu.vue";
import SystemStatus from "@/components/SystemStatus/SystemStatus.vue";

@Component({
  components: { UserMenu, SystemStatus },
  computed: { ...mapState(["headerTitle"]) },
})
export default class LightHeader extends Vue {}
</script>
