import axios from "axios";

export const http = axios.create({
  baseURL: location.origin,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

http.defaults.timeout = 120000;

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.request.use((request) => {
  request.url = request.url?.replaceAll("|", "%7C");
  return request;
});
