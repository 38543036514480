import { http } from "@/services/http/";
import responses from "@/services/responses";
import routes from "../../routes";
import { SecurityModel, SecurityGrantModel } from "@/store/modules/Security/model";
import { getInstance } from "@cruciallearning/puddle/auth";
import auditron from "@/utils/auditron";

const Security = {
  updateUser(data: SecurityModel): Promise<SecurityModel | string> {
    return http
      .put<SecurityModel>(`${routes(data.securityId).SECURITY_USER}`, data)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          console.log("updateUser failed: " + JSON.stringify(error.response));
          if (error.response.status === 403) return responses.REUSE;
          if (error.response.status === 404) return responses.NOT_FOUND;
          if (error.response.status === 406) return responses.FREQUENCY;
          if (error.response.status === 409) return responses.CONFLICT;
          if (error.response.status === 400) return responses.INVALID_PASSWORD;
        } else {
          console.log("updateUser failed");
        }
        return responses.FAILURE;
      });
  },
  async getUser(securityId: string): Promise<SecurityModel[] | null> {
    return http
      .get<SecurityModel[]>(routes(securityId).SECURITY_USER)
      .then((response) => response.data)
      .catch(async (error) => {
        if (error.response) {
          if (error.response.status === 404) console.log("Security account not found for id: " + securityId);
        }
        return null;
      });
  },
  async searchUser(search: string): Promise<SecurityModel[]> {
    return http
      .get<SecurityModel[]>(routes(search).SECURITY_SEARCH)
      .then((response) => response.data)
      .catch(() => []);
  },
  grantUserRole(id: string, data: SecurityGrantModel): Promise<SecurityModel | string | null> {
    const user = getInstance().authUser;
    data.roles.forEach((role) => {
      let newRole = role.role;
      if (role.scopes && role.scopes.length > 0) {
        newRole += "[" + role.scopes.join() + "]";
      }
      auditron.log(user, "USER", "role.grant", {
        oldValue: JSON.stringify(user.roles),
        newValue: newRole,
        changeId: user.id,
      });
    });

    return http
      .put<SecurityModel>(routes(id).SECURITY_GRANT, data)
      .then((response) => response.data)
      .catch((err) => {
        if (err.response.status === 409) return responses.CONFLICT;
        else return null;
      });
  },
  revokeUserRole(id: string, data: SecurityGrantModel): Promise<SecurityModel | null> {
    const user = getInstance().authUser;
    data.roles.forEach((role) => {
      let newRole = role.role;
      if (role.scopes && role.scopes.length > 0) {
        newRole += "[" + role.scopes.join() + "]";
      }
      auditron.log(user, "USER", "role.revoke", {
        oldValue: JSON.stringify(user.roles),
        newValue: newRole,
        changeId: user.id,
      });
    });

    return http
      .put<SecurityModel>(routes(id).SECURITY_REVOKE, data)
      .then((response) => response.data)
      .catch(() => {
        return null;
      });
  },
  getRoles(): Promise<string[] | null> {
    return http
      .get<string[]>(routes().SECURITY_ROLES)
      .then((response) => response.data)
      .catch(() => {
        return [];
      });
  },
  encrypt(input: string): Promise<string> {
    const url = `${routes().ENCRYPT}?data=${input}`;
    return http.get<string>(url).then((res) => res.data);
  },
  resetPassword(email: string): Promise<number> {
    const url = `${routes().SECURITY}/reset/${email}`;
    return http.post<string>(url).then((res) => res.status);
  },
  validateGrant(grant: string, email: string): Promise<number> {
    const url = `${routes().SECURITY_VALIDATE_GRANT}/?grant=${grant}&email=${email}`;
    return http
      .get<number>(url)
      .then((res) => res.status)
      .catch((err) => {
        if (err.response) {
          return err.response.status;
        }
        return 500;
      });
  },
};
export default Security;
