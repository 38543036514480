<template lang="pug">
component(:is="footerType")
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import StepFooter from "./Step/BaseFooterStep.vue";

@Component({
  components: { StepFooter },
  computed: { ...mapState(["footerType"]) },
})
export default class BaseFooter extends Vue {}
</script>
