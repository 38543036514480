import { http } from "@/services/http";
import routes from "@/services/routes";
import { EventConfig } from "@cruciallearning/puddle/models/event";

interface FindAllResponse {
  configs: EventConfig[];
}

const eventConfig = {
  createOrUpdate(params: {
    key: string;
    annotations?: boolean;
    backstageChat?: boolean;
    breakouts?: boolean;
    broadcast?: boolean;
    chat?: boolean;
    learnerGuide?: boolean;
    reactions?: boolean;
    resize?: boolean;
    stream?: boolean;
    userList?: boolean;
  }): Promise<number> {
    return http
      .post<void>(`${routes().TRAINING_EVENTS}/configs`, params)
      .then((res) => res.status)
      .catch((err) => {
        console.error("Error getting or updating event config", err);
        return err.status;
      });
  },
  findAll(): Promise<EventConfig[]> {
    return http
      .get<FindAllResponse>(`${routes().TRAINING_EVENTS}/configs`)
      .then((res) => res.data.configs)
      .catch(() => []);
  },
};

export default eventConfig;
