import { http } from "@/services/http/";
import routes from "../../routes";
import { AxiosResponse } from "axios";

type LearnerInfo = {
  browser: string;
  os: string;
  hasMic: boolean;
  hasCamera: boolean;
  audioBitrate?: string;
  videoBitrate?: string;
  audioPacketLossRatio?: string;
  videoPacketLossRatio?: string;
  frameRate?: string;
  recommendedFrameRate?: string;
  recommendedResolution?: string;
  errorMsg?: string;
};

const Learner = {
  saveSysInfo(id: string, data: LearnerInfo): Promise<AxiosResponse> {
    return http.post(`${routes().LEARNER}/${id}/systemInfo`, data);
  },
};
export default Learner;
