import { http } from "@/services/http/";
import routes from "../../routes";
import { AxiosResponse } from "axios";
import { ModeratorModel } from "@/store/modules/Moderator/model";

export type ModeratorCreateModel = {
  firstName: string;
  lastName: string;
  email: string;
  clientId: string;
  clientName: string;
  vendorName?: string;
  isCrucialLearningEmployee: boolean;
};

export type ModeratorReturnModel = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  clientId: string;
  clientName: string;
  vendorName?: string;
  isCrucialLearningEmployee: boolean;
};

export interface ModeratorEvent {
  eventId: string;
  courseContentStackId: string;
  referenceNumber: string;
  startDate: string;
  endDate: string;
}

export interface ModeratorEventsResponse {
  events: ModeratorEvent[];
}

const Moderator = {
  createModerator(data: ModeratorCreateModel): Promise<AxiosResponse> {
    return http.post(`${routes().MODERATORS}`, data);
  },
  getModerator(id: string): Promise<AxiosResponse> {
    return http.get(`${routes().MODERATORS}/${id}`);
  },
  listModerators(): Promise<AxiosResponse<ModeratorModel>> {
    return http.get(routes().MODERATORS);
  },
  updateModerator(id: string, data: ModeratorCreateModel): Promise<AxiosResponse> {
    return http.put(`${routes().MODERATORS}/${id}`, data);
  },
  deleteModerator(id: string): Promise<AxiosResponse> {
    return http.delete(`${routes().MODERATORS}/${id}`);
  },
  getUpcomingEvents(id: string): Promise<ModeratorEvent[]> {
    return http
      .post<ModeratorEventsResponse>(`${routes().TRAINING_EVENTS}/moderator/${id}`)
      .then((res) => {
        return res.data.events;
      })
      .catch((err) => {
        console.log("Error Occurred while pulling moderator events", err);
        return [];
      });
  },
};
export default Moderator;
