import { http } from "@/services/http";
import responses from "@/services/responses";
import routes from "@/services/routes";
import { LicenseCountModel, UnclaimedLicenseModel } from "@/store/modules/Report/model";
import { DateTime } from "luxon";

const License = {
  getUnusedLicenses(expirationDate: DateTime): Promise<UnclaimedLicenseModel | string> {
    return http
      .post<UnclaimedLicenseModel>(`${routes().REGISTRATION}/check_reclaimable_licenses`, { expirationDate })
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
        return responses.FAILURE;
      });
  },
  reclaimUnusedLicenses(expirationDate: DateTime): Promise<UnclaimedLicenseModel | string> {
    return http
      .post<UnclaimedLicenseModel>(`${routes().REGISTRATION}/reclaim_licenses`, { expirationDate })
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
        return responses.FAILURE;
      });
  },
  getLicenseCounts(salesForceId: string, courseId: string): Promise<LicenseCountModel | void> {
    return http
      .post<LicenseCountModel>(`${routes().REGISTRATION}/licenses_counts`, { salesForceId, courseId })
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
};
export default License;
