import { Module } from "vuex";
import { ModeratorState, ModeratorModel } from "./model";

const ModeratorModule: Module<ModeratorState, unknown> = {
  namespaced: true,
  state() {
    return {
      moderators: { content: [] },
    };
  },
  mutations: {
    setModeratorState(state: ModeratorState, stateChanges: Partial<ModeratorState>): void {
      Object.assign(state, stateChanges);
    },
    setModerators(state: ModeratorState, mods: ModeratorModel): void {
      Object.assign(state.moderators, mods);
    },
  },
};

export default ModeratorModule;
