const LoadScript = {
  load(src: string): Promise<HTMLScriptElement | null> {
    return new Promise((resolve, reject) => {
      let append = false;
      let el: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`);
      if (!el) {
        el = document.createElement("script");
        el.type = "text/javascript";
        el.async = true;
        el.src = src;
        append = true;
      } else if (el.hasAttribute("data-loaded")) resolve(el);

      el.addEventListener("error", reject);
      el.addEventListener("abort", reject);
      el.addEventListener("load", () => {
        el?.setAttribute("data-loaded", "true");
        resolve(el);
      });

      if (append) document.head.appendChild(el);
    });
  },

  unload(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const el: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`);
      if (!el) reject();
      else {
        document.head.removeChild(el);
        resolve();
      }
    });
  },
};

export default LoadScript;
