import { BaseElement, BaseState, NavigationItem } from "@/store/modules/Base/model";
import { ClientState } from "./modules/Client/model";
import { CourseState } from "@/store/modules/Course/model";
import { FilterState } from "@/store/modules/Filter/model";
import { EventState } from "@cruciallearning/puddle/models/event";
import { StepState } from "@/store/modules/Step/model";
import { UserState } from "./modules/User/model";
import { SecurityState } from "@/store/modules/Security/model";
import { StatusState } from "@/store/modules/SystemStatus/model";

/*
 * ------------------------------------------------------------------------------
 * Types/Enums
 * ------------------------------------------------------------------------------
 * The enums in the root store control which components load based on the type.
 * When the data is loaded it will contain the type and not the Enum. Type and
 * Enums work hand in hand to deliver a consistent experience depending on where
 * you are accessing the enum.
 */

export type SidebarType = "FILTER" | "STEP";
export enum SidebarTypes {
  FILTER = "BaseSidebarFilter",
  STEP = "BaseSidebarStep",
}

export type HeaderType = "LIGHT" | "DARK" | "NONE";
export enum HeaderTypes {
  LIGHT = "LightHeader",
  DARK = "DarkHeader",
  NONE = "NoHeader",
}

export type FooterType = "STEP";
export enum FooterTypes {
  STEP = "StepFooter",
}

/*
 * ------------------------------------------------------------------------------
 * Models
 * ------------------------------------------------------------------------------
 */
export interface RootState {
  backgroundColorClass: string;
  footerType: FooterTypes;
  headerTitle: string;
  headerType: HeaderTypes;
  isDataLoading: boolean;
  isFooterVisible: boolean;
  isReady: boolean;
  isSidebarVisible: boolean;
  language: string;
  lastRoute: string;
  navItem?: NavigationItem;
  sidebarType: SidebarTypes;
  // Modules for Intellisense
  //-------------------------
  ClientModule?: ClientState;
  UserModule?: UserState;
  BaseModule?: BaseState;
  CourseModule?: CourseState;
  FilterModule?: FilterState;
  EventModule?: EventState;
  StepModule?: StepState;
  SecurityModule?: SecurityState;
  StatusModule?: StatusState;
  //-------------------------
}
export interface HeaderConfig {
  headerType?: boolean;
  headerTitle?: string;
}
export interface SidebarConfig {
  isSidebarVisible?: boolean;
  sidebarType?: SidebarTypes;
}
export interface FooterConfig {
  isFooterVisible?: boolean;
  footerType?: FooterTypes;
}

//--> Generic Models
// These are multi-use models used in multiple spots
// across the application and components
export interface IdNameModel {
  id: number | string;
  name: string;
}
export interface IdNameBusyModel extends IdNameModel {
  busy: boolean;
}
export interface KeyValueModel {
  [key: string]: string | number | string[] | number[];
}

export interface IconModel extends IdNameModel {
  icon: string;
  csIconName: string;
}

/*
 * ------------------------------------------------------------------------------
 * Functions
 * ------------------------------------------------------------------------------
 */
export interface SetRootState {
  (rootStateOptions: Partial<RootState>): void;
}
export interface GetElementByType {
  (type: number | string): BaseElement | undefined;
}
